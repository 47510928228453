
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

@Component
export default class DeleteTestDialog extends Vue {
  @Prop() show!: boolean;

  @Prop() name?: string;

  @Prop() grade?: string;

  loading = false;

  get isDeleteAll(): boolean {
    return !!this.grade;
  }

  @Emit()
  confirmDelete(): void {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.close();
    }, 1000);
    console.log();
  }

  @Emit()
  close(): void {
    console.log();
  }
}
