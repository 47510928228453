
import { UserDto } from '@/api/types';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import DeleteTestDialog from './DeleteTestDialog.vue';

@Component({
  components: { DeleteTestDialog },
})
export default class DeleteTestButton extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop()
  student!: UserDto;

  showDialog = false;

  openDialog() {
    this.showDialog = true;
  }

  closeDialog() {
    this.showDialog = false;
  }

  async deleteTestResults() {
    try {
      await this.schoolModule.deleteTestResultFromStudent({
        schoolId: this.student.schoolId.toString(),
        studentId: this.student._id,
      });
      await this.schoolModule.getClassStudents(this.student.grade);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageTests.delete.success.single').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageTests.delete.error').toString(),
        isSuccess: false,
      });
    }
  }
}
