
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import DeleteTestDialog from './DeleteTestDialog.vue';

@Component({
  components: { DeleteTestDialog },
})
export default class DeleteAllTestButton extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  showDialog = false;

  openDialog() {
    if (this.teacher.grade && this.teacher.grade.length > 0) {
      this.showDialog = true;
    } else {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageTests.delete.noClass').toString(),
        isSuccess: false,
      });
    }
  }

  closeDialog() {
    this.showDialog = false;
  }

  get teacher() {
    return this.userModule.user;
  }

  async deleteTestResults() {
    try {
      if (
        this.teacher.schoolId &&
        this.teacher.grade &&
        this.teacher.grade.length > 0
      ) {
        await this.schoolModule.deleteAllTestsFromClass({
          schoolId: this.teacher.schoolId.toString(),
        });

        await this.schoolModule.getClassStudents(this.teacher.grade);

        this.snackbarModule.showMessage({
          message: this.$i18n.t('manageTests.delete.success.all').toString(),
          isSuccess: true,
        });
      }
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageTests.delete.error').toString(),
        isSuccess: false,
      });
    }
  }
}
